import { lazy } from 'react';
import { ContentLoad, MainLoad } from 'components/LoadScreen';
const MainLayout = MainLoad(lazy(() => import('layout/MainLayout')));

const Blank = ContentLoad(lazy(() => import('pages/other-pages/Blank')));
const Profile = ContentLoad(lazy(() => import('pages/other-pages/Profile')));
const Personnels = ContentLoad(lazy(() => import('pages/other-pages/Personnels')));
const Depart = ContentLoad(lazy(() => import('pages/interventions/Depart')));
const Rapports = ContentLoad(lazy(() => import('pages/rapports/Rapports')));
const EditRapports = ContentLoad(lazy(() => import('pages/rapports/EditRapports')));

const MainRoutes = {
    path: '/',
    element: <MainLayout authenticate={true} />,
    children: [
        {
            path: '',
            element: <Depart />
        },
        {
            path: 'profile',
            element: <Profile />
        },
        {
            path: 'personnels',
            element: <Personnels />
        },
        {
            path: 'rapports/:id',
            element: <EditRapports />
        },
        {
            path: 'rapports',
            element: <Rapports />
        },
        {
            path: 'auth',
            element: <Blank />
        }
    ]
};

export default MainRoutes;
