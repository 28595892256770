import { lazy } from 'react';
import { ContentLoad, MainLoad } from 'components/LoadScreen';
const MainLayout = MainLoad(lazy(() => import('layout/MainLayout')));

const Blank = ContentLoad(lazy(() => import('pages/other-pages/Blank')));

const MainRoutes = {
    path: '/',
    element: <MainLayout authenticate={false} />,
    children: [
        {
            path: 'Blank',
            element: <Blank />
        }
    ]
};

export default MainRoutes;
