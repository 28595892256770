import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const LoaderWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50vh',
    zIndex: 2001,
    width: '100%',
    '& > * + *': {
        marginTop: theme.spacing(2)
    }
}));

const CircularLoader = () => (
    <LoaderWrapper>
        <CircularProgress color="primary" />
    </LoaderWrapper>
);

export default CircularLoader;
