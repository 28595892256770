import { Suspense } from 'react';

// project import
import LinearLoader from './LinearLoader';
import CircularLoader from './CircularLoader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const MainLoad = (Component) => {
    const WrappedComponent = (props) => (
        <Suspense fallback={<LinearLoader />}>
            <Component {...props} />
        </Suspense>
    );
    WrappedComponent.displayName = `MainLoad(${Component.displayName || Component.name || 'Component'})`;
    return WrappedComponent;
};

const ContentLoad = (Component) => {
    const WrappedComponent = (props) => (
        <Suspense fallback={<CircularLoader />}>
            <Component {...props} />
        </Suspense>
    );
    WrappedComponent.displayName = `ContentLoad(${Component.displayName || Component.name || 'Component'})`;
    return WrappedComponent;
};

export { MainLoad, ContentLoad };
