import { lazy } from 'react';

// project import
import { ContentLoad, MainLoad } from 'components/LoadScreen';
const MiniLayout = MainLoad(lazy(() => import('layout/MinimalLayout')));

// render - Releases
const Releases = ContentLoad(lazy(() => import('pages/other-pages/Releases')));

const MinimalLayout = {
    path: '/',
    element: <MiniLayout />,
    children: [
        {
            path: 'releases',
            element: <Releases />
        }
    ]
};

export default MinimalLayout;
